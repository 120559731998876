import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import Search from "./Search";
import { classNames } from "../../helpers/classNames";
import ScrollLoder from "../../common/ScrollLoder/ScrollLoder";
import Image from "../elements/Image";

const Select = ({
  divClass,
  xPlacement,
  dropdownData = [],
  dropdownClass,
  dropdownButtonClass,
  liStyle,
  selectedValue,
  label,
  labelClasses,
  errorType,
  errorText,
  isSearch,
  isEditList,
  setOptionsEditModal,
  setEditDataOption,
  buttonArrowIconClasses,
  buttonArrowIcon = "fa-regular fa-chevron-down",
  onChange = () => {},
  isDisabled = false,
  isNameDisabled = false,
  dropdownScrollable,
  handleScrollBottom = () => {},
  getSelectedValue = () => {},
  isLoding = false,
  ...props
}) => {
  const [selected, setSelected] = useState({});
  const [statusColor, setStatusColor] = useState();
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": "",
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  const status = useMemo(
    () => ({
      success:
        "!bg-emerald-100 !text-emerald-500 !text-xs !font-semibold py-1 px-2 rounded",
      danger:
        "!bg-rose-100 !text-rose-500 !text-xs !font-semibold py-1 px-2 rounded",
      warning:
        "!bg-amber-100 !text-amber-500 !text-xs !font-semibold py-1 px-2 rounded",
      info: "!bg-blue-100 !text-blue-500 !text-xs !font-semibold py-1 px-2 rounded",
      default:
        "!bg-slate-100 !text-slate-700 !text-xs !font-semibold py-1 px-2 rounded",
      "": "",
    }),
    []
  );

  useEffect(() => {
    if (selectedValue) {
      const index = dropdownData.findIndex(
        (item) => item._id === selectedValue || item.value === selectedValue
      );
      if (index !== -1) {
        setSelected(dropdownData[index]);
      }
      if (index === -1) {
        setSelected(selectedValue);
      }
      if (selected?.statusType) {
        setStatusColor(status[selected?.statusType]);
      }
    } else {
      setSelected({ name: "Please Select", value: "" });
    }
  }, [dropdownData, selected?.statusType, selectedValue, status]);
  const [filterData, setFilterData] = useState();

  useEffect(() => {
    if (dropdownData.length) {
      setFilterData([...dropdownData]);
    }
  }, [dropdownData]);

  const handleSearch = (val) => {
    if (val) {
      setFilterData([
        ...dropdownData.filter((item, index) =>
          item.name
            .toString()
            .toLowerCase()
            .includes(val.toString().toLowerCase())
        ),
      ]);
    } else {
      setFilterData([...dropdownData]);
    }
  };
  // const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // const loadMore = () => {
  //   if (isLoading || !hasMore) return;

  //   setIsLoading(true);
  //   loadMoreData()
  //     .then((newData) => {
  //       setFilterData((prev) => [...prev, ...newData]);
  //       setHasMore(newData.length > 0); // If the response has no more data, set hasMore to false
  //     })
  //     .finally(() => setIsLoading(false));
  // };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight ===
      Math.floor(e.target.scrollTop) + e.target.clientHeight;

    if (bottom) {
      // setIsLoading(true); // Show loader
      handleScrollBottom(); // Stop loading after fetching
    }
  };

  return (
    <>
      <div className={classNames("relative  w-full", divClass)}>
        {label && (
          <div
            className={classNames(
              "text-sm font-medium text-slate-500 tracking-none mb-1",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        <Listbox
          value={selected}
          onChange={(val) => {
            onChange(val?.value);
            setSelected({ ...val });
            getSelectedValue(val);
          }}
          as={"div"}
          // trigger="click"
          className={`relative ${
            isDisabled ? "pointer-events-none opacity-60" : ""
          }`}
          disabled={isDisabled}
          // open={true}
        >
          {({ open }) => (
            <>
              <Listbox.Button
                ref={setTargetElement}
                className="flex items-center w-full"
              >
                <div
                  className={classNames(
                    "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-1 pl-3 pr-6 bg-white rounded-md border text-sm ",
                    dropdownButtonClass,
                    open ? "border-blue-500" : "border-slate-200"
                  )}
                >
                  {selected?.image && (
                    <div className="w-4 aspect-1 overflow-hidden rounded-md flex-shrink-0">
                      <Image
                        src={selected?.image}
                        alt={""}
                        effect={"blur"}
                        className={"object-cover"}
                      />
                    </div>
                  )}
                  <div
                    className={classNames(
                      "text-slate-600 whitespace-nowrap truncate text-sm",
                      statusColor ? statusColor : ""
                    )}
                  >
                    {!isNameDisabled && selected?.name}
                  </div>
                  {selected?.description && (
                    <div
                      className={classNames(
                        "text-slate-600 whitespace-nowrap truncate text-[12px]",
                        statusColor ? statusColor : ""
                      )}
                    >
                      {!isNameDisabled && selected?.description}
                    </div>
                  )}
                  {buttonArrowIcon && (
                    <div
                      className={classNames(
                        "absolute top-1/2 right-1 -translate-y-1/2 transition-all duration-200 text-xs text-slate-600",
                        open ? "-rotate-180" : "",
                        buttonArrowIconClasses
                      )}
                    >
                      <i className={classNames("fa-fw", buttonArrowIcon)}></i>
                    </div>
                  )}
                </div>
              </Listbox.Button>
              <Transition
                as={Fragment}
                className="z-50"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}
              >
                <Listbox.Options
                  static
                  className={classNames(
                    "absolute mt-1 border border-slate-200 w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1 ",
                    dropdownClass
                  )}
                >
                  {isSearch && (
                    <div className="py-2 px-3">
                      <Search
                        placeholder={"Search"}
                        search={(val) => {
                          handleSearch(val);
                          setSearchValue(val);
                        }}
                        value={searchValue}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </div>
                  )}
                  <div
                    className={classNames(
                      `py-1 max-h-72 overflow-y-scroll scrollbar divide-y divide-slate-100`,
                      dropdownScrollable
                    )}
                    onScroll={(e) => handleScroll(e)}
                  >
                    {filterData?.map((item, index) => {
                      if (!item) return null;
                      return (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            classNames(
                              "relative group flex w-full items-center px-6 py-1.5  text-sm gap-2 transition-all duration-200 cursor-pointer",
                              liStyle,
                              active
                                ? "bg-blue-50 text-blue-600"
                                : "text-slate-700",
                              item.isDisabled
                                ? "!bg-slate-400 !cursor-not-allowed"
                                : "",
                              item.class
                            )
                          }
                          value={item}
                          disabled={item.isDisabled}
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center gap-2 ml-2">
                                {item.image && (
                                  <div className="w-4 aspect-1 overflow-hidden rounded-md flex-shrink-0">
                                    <Image
                                      src={item.image}
                                      alt={""}
                                      effect={"blur"}
                                      className={"object-cover"}
                                    />
                                  </div>
                                )}
                                <div className="flex flex-col gap-2">
                                  <div className="text-sm !leading-none">
                                    {item.name}
                                  </div>
                                  {item.description && (
                                    <div className="text-[10px] 2xl:text-xs text-slate-600 !leading-none">
                                      {item.description}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {selected && (
                                <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-blue-500 text-base">
                                  <i className="fa-regular fa-fw fa-check"></i>
                                </div>
                              )}

                              {item.icon && (
                                <i
                                  className={classNames(
                                    "fa-fw text-blue-700",
                                    item.icon
                                  )}
                                ></i>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                    {isLoding && (
                      <Listbox.Option
                        className={
                          "flex items-center justify-center mx-auto pt-2"
                        }
                      >
                        <ScrollLoder />
                      </Listbox.Option>
                    )}
                    {isEditList && (
                      <Listbox.Option
                        onClick={() => {
                          setOptionsEditModal(true);
                          setEditDataOption(dropdownData);
                        }}
                        className="relative group flex w-full items-center px-3 py-2 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer hover:bg-blue-100 text-blue-500 font-semibold border-t border-slate-200"
                      >
                        Edit List
                      </Listbox.Option>
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
        {errorType && errorType === "error" && (
          <div className="text-xs text-red-600 mt-1">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs text-amber-600 mt-1">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs text-green-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs text-sky-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default Select;
