import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  siteSetting: null,
};

const sideSettingSlice = createSlice({
  name: "siteSetting",
  initialState,
  reducers: {
    setSiteSetting: (state, action) => {
      state.siteSetting = action.payload;
    },
  },
});

export const { setSiteSetting } = sideSettingSlice.actions;
export default sideSettingSlice.reducer;
