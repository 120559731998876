import { classNames } from "../../helpers/classNames";
import React, { useEffect, useState } from "react";

export default function CustomPagination({
  pageCount = 0,
  onPageChange = () => {},
  currentPage = 0,
  paginationClasses,
}) {
  const [selectedPage, setSelectedPage] = useState(0);
  const [totalPagesList, setTotalPagesList] = useState([]);

  useEffect(() => {
    setSelectedPage(currentPage || 0);
  }, [currentPage]);

  function generatePaginationList(selectedPage, totalPages) {
    const maxPagesToShow = 5;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      const pagesToShow = maxPagesToShow - 2;
      let startPage = Math.max(1, selectedPage - Math.floor(pagesToShow / 2));
      let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

      if (endPage - startPage < pagesToShow - 1) {
        startPage = Math.max(1, endPage - pagesToShow + 1);
      }

      if (startPage > 1) pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) pages.push(i);
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  }

  useEffect(() => {
    setTotalPagesList(generatePaginationList(selectedPage, pageCount));
  }, [selectedPage, pageCount]);

  return (
    <div
      className={classNames(
        "flex justify-center items-center",
        paginationClasses
      )}
    >
      <div className="flex gap-2 md:gap-4 w-fit items-center">
        {/* Left Arrow */}
        <div
          className="flex justify-center items-center text-blue-600 hover:text-blue-500 text-2xl cursor-pointer transition-all ease-in-out duration-200 transform hover:scale-110"
          onClick={() => currentPage > 0 && onPageChange({ selected: 0 })}
        >
          <i className="fa-solid fa-caret-left"></i>
        </div>

        {/* Page Numbers */}
        {totalPagesList?.map((number) => (
          <div
            key={number}
            onClick={() => {
              if (number !== "...") {
                setSelectedPage(number - 1);
                onPageChange({ selected: number - 1 });
              }
            }}
            className={`flex justify-center items-center rounded-full w-8 md:w-8 h-8 md:h-8 font-semibold text-sm transition-all ease-in-out duration-200 cursor-pointer ${
              number === "..."
                ? "text-gray-400 cursor-default"
                : selectedPage === number - 1
                ? "bg-blue-500 text-white"
                : "bg-blue-100 text-blue-700 hover:bg-blue-300"
            }`}
          >
            {number}
          </div>
        ))}

        {/* Right Arrow */}
        <div
          className="cursor-pointer flex justify-center items-center text-blue-600 hover:text-blue-500 text-2xl transition-all ease-in-out duration-200 transform hover:scale-110"
          onClick={() =>
            currentPage < pageCount - 1 &&
            onPageChange({ selected: pageCount - 1 })
          }
        >
          <i className="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </div>
  );
}
