import React from "react";

const SyncListSkeleton = ({ count = 6, type = "" }) => {
  console.log(type, "type");
  return (
    <div className=" bg-slate-50 p-4 rounded-md shimmer">
      <div className="relative">
        <div className="flex justify-between items-center gap-4 p-4">
          <div className="h-5 w-60 bg-slate-300 rounded-md"></div>
          <div className="h-8 w-48 bg-slate-300 rounded-md"></div>
        </div>
        <div className="flex justify-start items-center gap-4 p-3 -mt-3">
          <div className="h-8 w-48 bg-slate-300 rounded-md"></div>
        </div>
      </div>
      {
        (type === "employee" && (
          <div className="flex flex-col gap-6 p-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-375px)]">
            {Array.from({ length: count }).map((_, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-4 flex items-center gap-4 md:gap-6 w-full min-h-[55px]"
              >
                <div className="flex justify-start px-2">
                  <div className="flex items-center justify-between py-2">
                    <div className="w-5 h-5 bg-slate-200 rounded-full"></div>
                  </div>
                </div>
                <div className="overflow-hidden rounded-full flex items-center justify-center flex-shrink-0 w-8 h-8 bg-slate-200"></div>
                <div className="flex-1">
                  <div className="h-4 bg-slate-200 rounded-md w-2/3 mb-2"></div>
                  <div className="h-3 bg-slate-200 rounded-md w-1/3"></div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="w-8 h-8 bg-slate-200 rounded-full mb-2"></div>
                  <div className="h-3 bg-slate-200 rounded-md w-16"></div>
                </div>
              </div>
            ))}
          </div>
        ))
      }
      {
        (type === "position" && (
          <div className="grid grid-cols-2 gap-4 p-4  overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-375px)]">
            {Array.from({ length: count }).map((_, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-4 flex items-center gap-4 md:gap-6 w-full min-h-[55px]"
              >
                <div className="flex justify-start px-2">
                  <div className="flex items-center justify-between py-2">
                    <div className="w-5 h-5 bg-slate-200 rounded-full"></div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="h-4 bg-slate-200 rounded-md w-3/3 mb-2"></div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="w-8 h-8 bg-slate-200 rounded-full mb-2"></div>
                  <div className="h-3 bg-slate-200 rounded-md w-16"></div>
                </div>
              </div>
            ))}
          </div>
        ))
      }
    </div>
  );
};

export default SyncListSkeleton;
