import ReactPaginate from "react-paginate";

const Pagination = ({
  totalPages = 5,
  currentPage = 0,
  onPageChange = () => { },
}) => {
  const handlePageChange = (selectedPage) => {
    onPageChange(selectedPage.selected);
  };

  return (
    <div>
      <ReactPaginate
        className={"flex items-center flex-wrap gap-3"}
        pageClassName={
          "w-8 h-8 aspect-square  bg-white rounded-full overflow-hidden "
        }
        pageLinkClassName="px-2 bg-transparent w-full h-full flex items-center justify-center text-xs text-blue-500"
        onPageChange={handlePageChange}
        pageCount={totalPages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        activeClassName={"!bg-blue-500"}
        activeLinkClassName="text-white text-base"
        forcePage={currentPage}
        previousLabel={<i className="fa-regular fa-arrow-left" />}
        previousClassName={"w-auto h-7 md:h-10"}
        previousLinkClassName="px-2 rounded-full overflow-hidden w-full h-full flex items-center justify-center font-medium text-sm text-blue-500"
        nextLabel={<i className="fa-regular fa-arrow-right" />}
        nextClassName={"w-auto h-7 md:h-10"}
        nextLinkClassName="px-2 rounded-full overflow-hidden w-full h-full flex items-center justify-center uppercase font-medium text-xs text-blue-500"
        breakLabel={"..."}
        containerClassName={"pagination"}
        disabledClassName="cursor-none"
        disabledLinkClassName={"opacity-50"}
      />
    </div>
  );
};

export default Pagination;
