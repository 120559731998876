import { fetchData } from "../helpers/fetchData";

export const getUserList = async (params) => {
  try {
    const res = await fetchData("get", "/users-list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getPositionList = async (params) => {
    try {
      const res = await fetchData("get", "/position", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  export const getRoleList = async (params) => {
    try {
      const res = await fetchData("get", "/roles", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  

export const syncGenarateToken = async (params) => {
  try {
    const res = await fetchData("post", "generate/token", {
      body: params,
    });

    return res?.data;
  } catch (error) {
    return error;
  }
};
