ORGCHART_API_URL = "https://api.orgchrat.iosx.in/api/v1/";
ORGCHART_BASE_URL = "https://front.orgchart.iosx.in";

SUCCESSION_API_URL = "https://api.successionnow.com/api/v1/";
SUCCESSION_BASE_URL = "https://successionnow.com";


export var ORGCHART_API_URL;
export var SUCCESSION_API_URL;
export var ORGCHART_BASE_URL;
export var SUCCESSION_BASE_URL;