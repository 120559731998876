import React from "react";
import { Link } from "react-router-dom";
import ButtonLoder from "../../common/buttonLoder/ButtonLoder";
import Image from "../elements/Image";

const Button = ({
  buttonClasses,
  buttonType,
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction,
  buttonHasLink,
  buttonLink,
  functions,
  buttonDisabled,
  isLoaderDisabled = false,
  isImage = false,
  imageUrl,
  ...props
}) => {
  return (
    <>
      {buttonHasLink ? (
        <Link
          to={buttonLink}
          className={
            "bg-blue-500 flex items-center justify-center gap-1 text-white text-center rounded text-base h-10 py-0 px-2 2xl:px-3 transition-all duration-200 " +
            buttonClasses
          }
        >
          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
          {buttonLabel && (
            <span className={"text-sm font-medium whitespace-nowrap " + buttonLabelClasses}>
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </Link>
      ) : (
        <button
          type={buttonType}
          className={
            `bg-blue-500 flex ${
              buttonDisabled && "opacity-60"
            } items-center justify-center gap-1 text-white text-center rounded text-base  h-10 py-0 px-2 2xl:px-3 transition-all duration-200 ` +
            buttonClasses
          }
          onClick={buttonFunction}
          disabled={buttonDisabled}
        >
          {buttonDisabled && !isLoaderDisabled && (
            <div className="w-4">
              <ButtonLoder />
            </div>
          )}

          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
           {isImage && (
            <div className="w-5 aspect-1 overflow-hidden rounded-md flex-shrink-0">
              <Image
                src={imageUrl}
                alt={""}
                effect={"blur"}
                className={"object-cover"}
              />
            </div>
          )}

          {buttonLabel && (
            <span className={"text-sm font-medium whitespace-nowrap " + buttonLabelClasses}>
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
