import React from "react";
import { classNames } from "../../helpers/classNames";

const Checkbox = ({
  checkboxName,
  checkboxInputClass,
  isChecked,
  isDisable,
  checkboxClass,
  checkboxLabel,
  checkboxLableClass,
  strike = false,
  divClass,
  value,
  onChange = () => {},
  ...props
}) => {
  return (
    <>
      <div
        className={classNames(
          "relative overflow-hidden flex flex-shrink-0",
          divClass
        )}
      >
        <input
          type="checkbox"
          name={checkboxName}
          className={classNames(
            "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer",
            checkboxInputClass
          )}
          disabled={isDisable}
          checked={isChecked}
          value={value}
          onChange={onChange}
        />
        <div
          className={classNames(
            "w-5 h-5 flex-shrink-0 border border-slate-200 rounded flex items-center justify-center text-sm bg-white text-black/0 peer-checked:bg-blue-500 peer-checked:border-blue-500 peer-checked:text-white",
            checkboxClass
          )}
        >
          <i className="fa-light fa-check"></i>
        </div>
        {checkboxLabel && (
          <div
            className={classNames(
              "text-sm text-slate-500 w-full ml-2 font-medium transition-all duration-200",
              strike ? "peer-checked:line-through" : "",
              checkboxLableClass
            )}
          >
            {checkboxLabel}
          </div>
        )}
      </div>
    </>
  );
};

export default Checkbox;
