const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const handleOnDragEnd = (
  result,
  list = [],
  setList = () => {},
  callback = () => {}
) => {
  const items = reorder(list, result.source.index, result.destination.index);
  setList(items);
  callback(items);
};

export const initialsValue = (str = "") => {
  const strArr = str?.split(" ");
  const initials =
    (strArr &&
      Array.isArray(strArr) &&
      strArr[0]
        .slice(0, 1)
        .concat(strArr?.length > 1 ? strArr.at(-1).slice(0, 1) : "")
        .toUpperCase()) ||
    [];
  return initials;
};
