import { createSlice } from "@reduxjs/toolkit";

const questionSlice = createSlice({
  name: "questions",
  initialState: { potential: [], performance: [] },
  reducers: {
    setQuestions: (state, action) => {
      state.potential = action.payload.filter(
        (item) => item?.category === "potential"
      );
      state.performance = action.payload.filter(
        (item) => item?.category === "performance"
      );
    },
  },
});
export const { setQuestions } = questionSlice.actions;
export default questionSlice.reducer;
