import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import { useDataSyncModal } from "../../Context/SyncDataModalContext";
import AvtarImage from "../../components/elements/AvtarImage";
import { initialsValue } from "../../helpers";
import Loader from "../../components/loader/Loader";
import syncFailed from "../../assets/images/syncFaild.png";
import syncSuccess from "../../assets/images/syncSucess.png";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import {
  ORGCHART_API_URL,
  ORGCHART_BASE_URL,
  SUCCESSION_API_URL,
  SUCCESSION_BASE_URL,
} from "../../config/host";
import SyncingLoader from "../../components/loader/SyncingLoader";
import Pagination from "../../common/Pagination";
// import PositionList from "../section/syncSection/PositionList";
// import SyncListSkeleton from "../loader/SyncListSkeleton";
import Select from "../form/Select";
import { toast } from "react-toastify";
import SyncListSkeleton from "../../components/loader/SyncListSkeleton";
import Checkbox from "../form/Checkbox";
// import PositionList from "../sections/front/syncSection/PositionList";
// import SelectDropdownSearch from "../form/SelectDropdownSearch";
import { getRoleList, syncGenarateToken } from "../../Services/SsoService";
import CustomPagination from "../elements/CustomPagination";
import PositionList from "./syncSection/PositionList";

const SyncDataPopup = () => {
  const {
    syncDataModalOpen,
    setSyncDataModalOpen,
    userList,
    setUserList,
    loadUserList,
    loading,
    syncingLoader,
    syncingStep,
    keyword,
    setKeyword,
    limit,
    setLimit,
    offset,
    setOffset,
    totalDataCount,
    setTotalDataCount,
    startDataCount,
    setStartDataCount,
    endDataCount,
    setEndDataCount,
    noOfPage,
    setNoOfPage,
    currentPage,
    setCurrentPage,
    selectedRole,
    setSelectedRole,
    pageCount,
    setPageCount,
  } = useDataSyncModal();

  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [arePositionAllSelected, setArePositionAllSelected] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [tabType, setTabType] = useState("employees");
  const [roleList, setRoleList] = useState({
    loading: false,
    data: [],
    totalItem: 0,
  });

  useEffect(() => {
    if (
      syncDataModalOpen &&
      syncingStep?.step === "2"
      // &&
      // userList?.length === 0
    )
      loadUserList();
  }, [
    syncDataModalOpen,
    //  userList?.length,
    syncingStep,
  ]);

  //   if (!syncDataModalOpen) return null;

  const handleSelectedMember = (item) => {
    setSelectedMember((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (member) => member?._id === item?._id
      );

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredMembers = userList?.filter((member) => member._id);

    if (selectedMember.length === filteredMembers.length) {
      setSelectedMember([]);
    } else {
      setSelectedMember(filteredMembers);
    }
  };

  useEffect(() => {
    if (syncDataModalOpen) {
      const filteredMembers =
        userList &&
        Array.isArray(userList) &&
        userList?.length > 0 &&
        userList?.filter((member) => member?._id);
      const isAllSelected =
        selectedMember.length === filteredMembers.length &&
        filteredMembers.length !== 0;

      if (isAllSelected) {
        setAreAllSelected(true);
      } else {
        setAreAllSelected(false);
      }
    }
  }, [selectedMember, userList, areAllSelected, syncDataModalOpen]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const syncEmployee = async (url, headersToken) => {
    const updatedUserList = userList?.map((user) => {
      const isSelected = selectedMember.some(
        (member) => member._id === user._id
      );
      if (isSelected) {
        return { ...user, syncingStatus: "processing" };
      }
      return user;
    });
    setUserList(updatedUserList);
    // try {
    //   while (selectedMember.length > 0) {
    //     const currentEmployee = selectedMember[0];

    //     // Validate currentEmployee fields
    //     if (
    //       !currentEmployee ||
    //       !currentEmployee.company_info?.email ||
    //       !currentEmployee.name ||
    //       !currentEmployee.email
    //     ) {
    //       console.error("Invalid employee data:", currentEmployee);
    //       toastr.error("Invalid employee data encountered.");
    //       selectedMember.shift();
    //       continue;
    //     }

    //     const path = url;
    //     const payload = {
    //       companyEmail: currentEmployee.company_info?.email,
    //       name: currentEmployee.name,
    //       email: currentEmployee.email,
    //       userId: currentEmployee?._id,
    //       position_details:
    //         Array.isArray(currentEmployee.position_details) &&
    //         currentEmployee.position_details.length > 0
    //           ? currentEmployee.position_details[0]
    //           : {},
    //       role_details:
    //         Array.isArray(currentEmployee.role_details) &&
    //         currentEmployee.role_details.length > 0
    //           ? currentEmployee.role_details[0]
    //           : {},
    //       code: "successionNow",
    //     };

    //     try {
    //       const res = await axios.post(path, payload, {
    //         headers: {
    //           Authorization: `Bearer ${headersToken}`,
    //           "Content-Type": "application/json",
    //         },
    //       });

    //       if (res?.data?.status === 200) {
    //         // toastr.success(`Successfully synced: ${currentEmployee.name}`);
    //         setUserList((prevList) =>
    //           prevList.map((user) =>
    //             user._id === currentEmployee._id
    //               ? { ...user, syncingStatus: "success" }
    //               : user
    //           )
    //         );
    //         selectedMember.shift(); // Remove the synced employee from the list
    //       } else {
    //         setUserList((prevList) =>
    //           prevList.map((user) =>
    //             user._id === currentEmployee._id
    //               ? { ...user, syncingStatus: "failed" }
    //               : user
    //           )
    //         );
    //         selectedMember.shift();
    //         console.log(`Failed to sync: ${currentEmployee.name}`);
    //         // break; // Stop processing further employees
    //       }
    //     } catch (err) {
    //       console.error(`Error syncing employee: ${currentEmployee.name}`, err);
    //       setUserList((prevList) =>
    //         prevList.map((user) =>
    //           user._id === currentEmployee._id
    //             ? { ...user, syncingStatus: "failed" }
    //             : user
    //         )
    //       );
    //       if (err.response) {
    //         console.error("Server error:", err.response.data);
    //         toastr.error(
    //           `Error: ${err.response.data.message || "Server error occurred."}`
    //         );
    //       } else if (err.request) {
    //         // Request was made but no response received
    //         console.error("No response received:", err.request);
    //         toastr.error("Network error. Please try again later.");
    //       } else {
    //         console.error("Unexpected error:", err.message);
    //         toastr.error(`Unexpected error: ${err.message}`);
    //       }

    //       selectedMember.shift();
    //       // break;
    //     }
    //   }

    //   if (selectedMember.length === 0) {
    //     console.log("All employees have been successfully synced!");
    //   } else {
    //     console.log(
    //       "Sync process stopped. Remaining employees:",
    //       selectedMember
    //     );
    //   }
    // } catch (err) {
    //   console.error("Critical error during the sync process:", err);
    //   toastr.error("A critical error occurred. Please try again later.");
    // }
  };

  const getGenarateToken = async (DOMAIN, API_URL = "") => {
    console.log({ DOMAIN, API_URL });

    const updatedUserList = userList?.map((user) => {
      const isSelected = selectedMember.some(
        (member) => member._id === user._id
      );
      if (isSelected) {
        return { ...user, syncingStatus: "processing" };
      }
      return user;
    });
    setUserList(updatedUserList);
    // try {
    //   const res = await syncGenarateToken({
    //     domain: DOMAIN,
    //   });
    //   if (res?.status === 200) {
    //     const url = API_URL + "sync-employees";
    //     const headersToken = res?.data;
    //     syncEmployee(url, headersToken);
    //   } else {
    //     toast.error(res?.data?.message || "Something went wrong");
    //   }
    // } catch (err) {
    //   console.log(err);
    //   toast.error("Somthing went wrong !!!");
    // }
  };

  console.log(selectedMember, "selectedMember");

  useEffect(() => {
    setRoleList((pre) => ({ ...pre, data: [], loading: true }));
    getRoleList({
      limit: 100,
    }).then((res) => {
      if (res && res?.status === 200) {
        setRoleList((pre) => ({
          ...pre,
          data: res?.docs || [],
          loading: false,
          totalItem: res?.totalDocs,
        }));
      } else {
        setRoleList((pre) => ({ ...pre, data: [], loading: false }));
      }
    });
  }, []);

  return (
    <Transition appear show={syncDataModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {syncingStep?.step === "1"
                    ? "Comapny Syncing"
                    : `Company Sync Dashboard (${
                        syncingStep?.selectedWebsite?.name || "N/A"
                      })`}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0 !border-transparent"
                    }
                    buttonFunction={() => {
                      setSyncDataModalOpen(false);
                      setSelectedRole("");
                    }}
                  />
                </div>
                <div className="relative mt-2 border bg-white border-gray-200 p-4  rounded-xl ">
                  {syncingStep?.step === "1" && (
                    <>
                      <div className="flex justify-center items-center min-h-[260px]  flex-col">
                        {syncingLoader && (
                          <>
                            <div className="">
                              <Loader />
                            </div>
                            <div className="flex text-center justify-center items-center text-xl font-bold text-blue-500">
                              {"Syncing Company..."}
                            </div>
                          </>
                        )}
                        {!syncingLoader && (
                          <>
                            {syncingStep?.status === 400 ? (
                              <>
                                <div className="w-16 h-16">
                                  <img src={syncFailed} alt="syncFailed" />
                                </div>
                                <div className="flex text-center justify-center items-center text-xl font-bold text-rose-600">
                                  {syncingStep?.message || ""}
                                </div>
                              </>
                            ) : syncingStep?.status === 200 ? (
                              <>
                                <div className="w-16 h-16">
                                  <img src={syncSuccess} alt="syncSuccess" />
                                </div>
                                <div className="flex text-center justify-center items-center text-xl font-bold text-green-600">
                                  {"Company Syncing Successful"}
                                </div>
                              </>
                            ) : syncingStep?.status === 417 ? (
                              <>
                                <div className="w-16 h-16">
                                  <img src={syncSuccess} alt="syncSuccess" />
                                </div>
                                <div className="flex text-center justify-center items-center text-xl font-bold text-blue-600">
                                  {syncingStep?.message ||
                                    "Comapany Already Exists"}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="w-16 h-16">
                                  <img src={syncFailed} alt="syncFailed" />
                                </div>
                                <div className="flex text-center justify-center items-center text-xl font-bold text-rose-600">
                                  {"Company Syncing Failed. Please Try Again."}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {syncingStep?.step === "2" && (
                    <>
                      <div className="flex justify-center items-center -mt-3 space-x-5 border-b border-gray-200 mb-5">
                        {["employees", "positions"].map((type) => (
                          <button
                            key={type}
                            type="button"
                            className={`inline-block py-2 px-6 border-b-4 rounded-t-lg transition-all duration-300 ease-in-out transform ${
                              tabType === type
                                ? "text-blue-600 border-blue-600 font-semibold scale-105"
                                : "text-slate-500 border-transparent hover:text-blue-500 hover:border-blue-500 hover:scale-105"
                            }`}
                            onClick={() => setTabType(type)}
                          >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                          </button>
                        ))}
                      </div>

                      {tabType === "employees" && (
                        <>
                          {!loading && (
                            <>
                              <div className=" bg-blue-50 p-4 rounded-md">
                                <h1 className=" font-semibold mb-4 mt-2">
                                  {/* {`Showing ${userList?.length} Results`} */}
                                  {selectedMember?.length > 0 && (
                                    <span className="text-sm text-gray-500 ml-2">
                                      {selectedMember.length} Employee
                                      {selectedMember.length > 1
                                        ? "s"
                                        : ""}{" "}
                                      Selected
                                    </span>
                                  )}
                                </h1>
                                <div className="relative flex gap-3 items-center mb-3 flex-wrap mt-2">
                                  <div className="w-full sm:w-36">
                                    <Select
                                      selectedValue={limit}
                                      dropdownData={[
                                        {
                                          name: "All Items",
                                          value: totalDataCount,
                                        },
                                        { name: "5 Items", value: 5 },
                                        { name: "10 Items", value: 10 },
                                        { name: "20 Items", value: 20 },
                                        { name: "30 Items", value: 30 },
                                        { name: "50 Items", value: 50 },
                                        { name: "100 Items", value: 100 },
                                      ]}
                                      getSelectedValue={(e) => {
                                        setLimit(e.value);
                                        setOffset(0);
                                        setCurrentPage(0);
                                        setSelectedMember([]);
                                      }}
                                    />
                                  </div>
                                  <div className="relative w-full sm:w-60">
                                    {/* <SelectDropdownSearch
                                      placeholder={"Select Role"}
                                      isSearch={true}
                                      xPlacement={"bottom"}
                                      buttonArrowIcon={
                                        "fa-solid fa-chevron-down"
                                      }
                                      tabTextColor={"text-slate-700"}
                                      loading={roleList?.loading}
                                      value={selectedRole}
                                      dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                                      dropdownData={roleList?.data?.map(
                                        (item) => ({
                                          name: item.code,
                                          _id: item.code,
                                        })
                                      )}
                                      onChange={(e) => {
                                        setSelectedRole(e.target.value);
                                        setOffset(0);
                                        setCurrentPage(0);
                                        setSelectedMember([]);
                                      }}
                                    /> */}
                                  </div>
                                  {userList &&
                                    Array.isArray(userList) &&
                                    userList?.length > 0 &&
                                    userList?.filter((member) => member?._id)
                                      .length > 0 && (
                                      <div
                                        className=""
                                        data-tooltip-id={`selectAllButton${1434}`}
                                        data-tooltip-place="bottom"
                                      >
                                        <Button
                                          buttonType={"button"}
                                          buttonClasses={
                                            "bg-transparent border border-blue-500 !text-blue-500 ml-2"
                                          }
                                          buttonLabelClasses="text-blue-500"
                                          buttonIcon={
                                            areAllSelected
                                              ? "fa-solid fa-square-check"
                                              : "fa-regular fa-square"
                                          }
                                          buttonIconPosition={"left"}
                                          buttonLabel={
                                            areAllSelected
                                              ? "Unselect All Employee"
                                              : "Select All Employee"
                                          }
                                          buttonHasLink={false}
                                          buttonFunction={() =>
                                            handleSelectAll()
                                          }
                                        />
                                        <Tooltip
                                          id={`selectAllButton${1434}`}
                                          className="!text-xs !z-50 rounded py-3 px-6"
                                          render={() => (
                                            <div className="gap-x-1">
                                              <div className="text-[13px] font-normal leading-none flex items-center">
                                                <div className="font-semibold">
                                                  {areAllSelected
                                                    ? "Unselect All Employee"
                                                    : "Select All Employee"}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        />
                                      </div>
                                    )}

                                  {selectedMember?.length > 0 && (
                                    <div className="ml-auto flex items-center">
                                      <Button
                                        buttonLabel={"Sync Employee"}
                                        buttonIcon={"fa-solid fa-rotate"}
                                        buttonIconPosition={"left"}
                                        buttonClasses={"!text-white"}
                                        buttonLabelClasses={"!text-white"}
                                        buttonFunction={() => {
                                          if (
                                            syncingStep?.selectedWebsite
                                              ?.code === "orgChart"
                                          ) {
                                            getGenarateToken(
                                              SUCCESSION_BASE_URL,
                                              SUCCESSION_API_URL
                                            );
                                          } else if (
                                            syncingStep?.selectedWebsite
                                              ?.code === "9BoxNow"
                                          ) {
                                            getGenarateToken(
                                              ORGCHART_BASE_URL,
                                              ORGCHART_API_URL
                                            );
                                          } else {
                                            getGenarateToken(
                                              ORGCHART_BASE_URL,
                                              ORGCHART_API_URL
                                            );
                                            toast.error(
                                              "Please Select a Website For Syncing"
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="flex flex-col gap-6 p-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-375px)]">
                                  {userList &&
                                    Array.isArray(userList) &&
                                    userList?.length > 0 &&
                                    userList?.map((user, index) => (
                                      <>
                                        <div className="bg-white shadow-lg rounded-lg p-4 flex items-center gap-4 md:gap-6 w-full min-h-[65px]">
                                          <div className="flex justify-start px-2">
                                            <div
                                              className="flex items-center justify-between py-2"
                                              key={index}
                                            >
                                              <Checkbox
                                                isChecked={selectedMember.some(
                                                  (member) =>
                                                    member?._id === user?._id
                                                )}
                                                checkboxClass={
                                                  "!border-blue-500"
                                                }
                                                onChange={() =>
                                                  handleSelectedMember(user)
                                                }
                                              />
                                              {/* <input
                                                type="checkbox"
                                                id={`${user?._id}`}
                                                checked={selectedMember.some(
                                                  (member) =>
                                                    member?._id === user?._id
                                                )}
                                                onChange={() =>
                                                  handleSelectedMember(user)
                                                }
                                                className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-blue-700   checked:hover:bg-blue-700 checked:bg-blue-700 "
                                              /> */}
                                            </div>
                                          </div>
                                          <AvtarImage
                                            initial={initialsValue(
                                              user?.userDetails?.firstName +
                                                " " +
                                                user?.userDetails?.lastName
                                            )}
                                            image={null}
                                            alt={user?.userDetails?.firstName}
                                            fill={true}
                                            className={""}
                                            size={"sm"}
                                            initialClass={" !text-sm"}
                                          />
                                          <div className="flex-1">
                                            <h3 className="flex gap-1 text-base font-semibold text-blue-500">
                                              {user?.userDetails?.firstName +
                                                " " +
                                                user?.userDetails?.lastName ||
                                                "N/A"}{" "}
                                              -
                                              <small className="flex text-slate-600 capitalize gap-2">
                                                {user?.userDetails
                                                  ?.roleDetails &&
                                                Array.isArray(
                                                  user?.userDetails?.roleDetails
                                                ) &&
                                                user?.userDetails?.roleDetails
                                                  .length > 0
                                                  ? user?.userDetails?.roleDetails
                                                      .map((role) => role?.name)
                                                      .join(", ")
                                                  : "N/A"}
                                              </small>
                                            </h3>
                                            <div className="text-sm text-gray-500 flex items-center gap-2">
                                              <span>
                                                {user?.userDetails?.email ||
                                                  "N/A"}
                                              </span>
                                            </div>
                                          </div>
                                          {user?.syncingStatus ===
                                            "processing" && (
                                            <div className="flex flex-col items-center">
                                              <Loader />
                                              <SyncingLoader />
                                            </div>
                                          )}
                                          {user?.syncingStatus ===
                                            "success" && (
                                            <div className="flex flex-col items-center">
                                              <div className="w-8 h-8">
                                                <img
                                                  src={syncSuccess}
                                                  alt="syncSuccess"
                                                />
                                              </div>
                                              <div className="flex text-center justify-center items-center text-sm font-semibold text-teal-600">
                                                {"Sync Finished"}
                                              </div>
                                            </div>
                                          )}
                                          {user?.syncingStatus === "failed" && (
                                            <div className="flex flex-col items-center">
                                              <div className="w-8 h-8">
                                                <img
                                                  src={syncFailed}
                                                  alt="syncFailed"
                                                />
                                              </div>
                                              <div className="flex text-center justify-center items-center text-sm font-semibold text-rose-600">
                                                {"Sync Failed"}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </div>
                              <CustomPagination
                                paginationClasses={"mt-5 justify-end"}
                                onPageChange={(val) => {
                                  setOffset(limit * val?.selected);
                                  setCurrentPage(val?.selected);
                                  setSelectedMember([]);
                                }}
                                pageCount={pageCount}
                                currentPage={currentPage}
                              />
                            </>
                          )}
                          {loading && <SyncListSkeleton type={"employee"} />}
                        </>
                      )}
                      {tabType === "positions" && (
                        <PositionList
                          syncingStep={syncingStep}
                          syncFailed={syncFailed}
                          syncSuccess={syncSuccess}
                          selectedPosition={selectedPosition}
                          setSelectedPosition={setSelectedPosition}
                          arePositionAllSelected={arePositionAllSelected}
                          setArePositionAllSelected={setArePositionAllSelected}
                          syncDataModalOpen={syncDataModalOpen}
                        />
                      )}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SyncDataPopup;
